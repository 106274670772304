import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
//import LandingHome from "../layout/home";
import {Loading} from '../layout/Loading';
//import PessahInfo from '../holidays/pessah/information';
import Solidarite from '../splash/solidarite';
import PessahInfo from '../holidays/pessah/information';
import SolidariteDetails from '../splash/solidariteDetails';
//

const Products = lazy(() => import("../products"));
const SingleProduct = lazy(() => import("../products/single"));
const Baskets = lazy(() => import("../products/baskets"));
const Forfait = lazy(() => import("../products/forfait"));

const Checkout = lazy(() => import("../payment/checkout"));

const Confirm = lazy(() => import("../cart/Cart"));
const CompleteStripe = lazy(() => import("../payment/completeStripe"));
const CompletePaypal = lazy(() => import("../payment/completePaypal"));
const Sepa = lazy(() => import("../subscription/Sepa"));

const About = lazy(() => import("../pages/about"));
const Concept = lazy(() => import("../pages/concept"));
const Layout = lazy(() => import("../layout/index"));
const Letters = lazy(() => import("../pages/letters"));
const CustomDonate = lazy(() => import("../donations/custom"));
const Home = lazy(() => import("../pages/home"));
const RochHashana = lazy(() => import("../holidays/roshHashana"));
const Contact = lazy(() => import("../pages/contact"));
const Souccot = lazy(() => import("../holidays/soucoth"));
const SouccotInfo = lazy(() => import("../holidays/soucoth/information"));
const Thanks = lazy(() => import("../payment/thanks"));

const Terms = lazy(() => import("../pages/terms"));
const Privacy = lazy(() => import("../pages/privacy"));

export function Routers() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/*<Route path="/" element={<Home />}>*/}
          {/*  <Route index element={<Home />} />*/}
          {/*</Route>*/}

          <Route element={<Layout />}>
            {/*<Route index element={<Home />} />*/}
            <Route index element={<Souccot />} />
            <Route path="soukot" element={<Souccot />} />
            <Route path="les-fete/soukot" element={<Souccot />} />
            <Route path="solidarite" element={<Solidarite />} />
            <Route path="solidarite/information" element={<SolidariteDetails />} />

            <Route
              path="les-fete/soukot/information"
              element={<SouccotInfo />}
            />
            <Route path="les-fete/roch-hashana" element={<RochHashana />} />
            <Route path="les-fete/pessah" element={<PessahInfo />} />
            <Route path="produits" element={<Products />} />
            <Route path="produits/:slug" element={<SingleProduct />} />
            <Route path="paniers" element={<Baskets />} />
            <Route path="forfait" element={<Forfait />} />
            <Route path="contactez-nous" element={<Contact />} />
            <Route
              path="faire-un-don"
              // @ts-ignore
              element={<CustomDonate />}
            />
            <Route path="confirm" element={<Confirm />} />
            <Route path="termine-paypal" element={<CompletePaypal />} />
            <Route path="paiement-termine" element={<CompleteStripe />} />
            <Route path="merci-bcp" element={<Thanks />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="sepa" element={<Sepa />} />
            <Route path="qui-sommes-nous" element={<About />} />
            <Route path="le-concept" element={<Concept />} />
            <Route path="lettres-de-recommandation" element={<Letters />} />
            <Route path="terms-and-conditions" element={<Terms />} />
            <Route path="privacy-policy" element={<Privacy />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routers;
